<template>
  <sign-page
    title-text="合同管理"
    :request="request"
    :column-list="columnList"
    table-size="large"
    @dataFileUpdate="dataFileUpdate"
    :title-menus="[{key: 'print', label: '批量打印'}, {key: 'add', label: '新增'}]"
    :table-actions="tableActions"
    @tableAction="tableAction"
    @clickTitleMenu="clickTitleMenu"
    @checkDataChange="checkDataChange"
    :form-parms-add="formParmsAdd"
    :dealFormData="dealFormData"
    :data-file-parm="dataFileParm"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :need-data-file="true">
    <a4-print ref="print" />
  </sign-page>
</template>

<script>
import {
  agreementRequest as request,
  workerJobTitleRequest
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

import A4Print from '@/components/a4print'

export default {
  components: { A4Print },
  computed: {
    dataFileParm () {
      return {
        pDirPath: '/agreement/'
      }
    },
    tableActions () {
      let data = [
        {
          key: 'data_file',
          label: '扫描件'
        },
        {
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        },
        {
          key: 'print',
          label: '打印'
        }
      ]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '合同类型',
          selectDatas: this.$store.getters.selectDatas['agreement_type_list'],
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '合同编号',
          key: 'code'
        },
        {
          type: 'select',
          label: '所属员工',
          key: 'workerId',
          selectDatas: this.$store.getters.workerList,
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '签订方式',
          key: 'signedType',
          selectDatas: this.$store.getters.selectDatas['agreement_signed_type_list'],
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '聘用单位',
          key: 'signedOrg'
        },
        {
          type: 'input',
          label: '派遣单位',
          key: 'title',
          show: (fData) => {
            return fData && fData.type === '劳务派遣合同'
          }
        },
        {
          type: 'datePicker',
          label: '签订时间',
          key: 'signedTime'
        },
        {
          type: 'select',
          label: '是否有试用期',
          key: 'needTry',
          selectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}],
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '试用期开始时间',
          key: 'tryStartDate',
          show: (formData) => {
            return formData.needTry === '是'
          },
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '试用期结束时间',
          key: 'tryEndDate',
          show: (formData) => {
            return formData.needTry === '是'
          },
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '起始时间',
          key: 'startDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '到期时间',
          key: 'endDate'
        },
        {
          type: 'datePicker',
          label: '终止时间',
          key: 'stopDate'
        },
        {
          type: 'input',
          label: '终止原因',
          key: 'stopReson'
        },
        {
          type: 'datePicker',
          label: '解除时间',
          key: 'disDate'
        },
        {
          type: 'input',
          label: '解除原因',
          key: 'disReson'
        },
        {
          type: 'input',
          label: '合同状态',
          key: 'status'
        },
        {
          type: 'input',
          label: '变更原因',
          key: 'cReson'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          field: 'selection',
          type: 'selection',
          search: false
        },
        {
          title: '编号',
          field: 'id',
          sort: true
        },
        {
          title: '合同编号',
          field: 'code',
          sort: true
        },
        {
          title: '合同类型',
          field: 'type',
          sort: true
        },
        {
          title: '员工姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '员工工号',
          field: 'workerCode',
          sort: true
        },
        {
          title: '签订方式',
          field: 'signedType',
          sort: true
        },
        {
          title: '聘用单位',
          field: 'signedOrg',
          sort: true
        },
        {
          title: '派遣单位',
          field: 'title',
          sort: true
        },
        {
          title: '签订时间',
          field: 'signedTime',
          width: 110,
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.signedTime ? rowData.signedTime.slice(0, 10) : '-')
          }
        },
        {
          title: '是否有试用期',
          field: 'needTry',
          sort: true
        },
        {
          title: '试用期开始时间',
          width: 130,
          field: 'tryStartDate',
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.tryStartDate ? rowData.tryStartDate.slice(0, 10) : '-')
          }
        },
        {
          title: '试用期结束时间',
          width: 130,
          field: 'tryEndDate',
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.tryEndDate ? rowData.tryEndDate.slice(0, 10) : '-')
          }
        },
        {
          title: '起始时间',
          width: 110,
          field: 'startDate',
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-')
          }
        },
        {
          title: '到期时间',
          width: 110,
          field: 'endDate',
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-')
          }
        },
        {
          title: '终止时间',
          field: 'stopDate',
          width: 110,
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.stopDate ? rowData.stopDate.slice(0, 10) : '-')
          }
        },
        {
          title: '终止原因',
          field: 'stopReson',
          sort: true
        },
        {
          title: '解除时间',
          field: 'disDate',
          width: 130,
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.disDate ? rowData.disDate.slice(0, 10) : '-')
          }
        },
        {
          title: '解除原因',
          field: 'disReson',
          sort: true
        },
        {
          title: '合同状态',
          field: 'status',
          sort: true
        },
        {
          title: '变更原因',
          field: 'cReson',
          sort: true
        },
        {
          title: '扫描件状态',
          field: 'fileStatus',
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.fileUploadTime ? '已上传' : '未上传')
          }
        },
        {
          title: '最近更新扫描件时间',
          field: 'fileUploadTime',
          dataType: Date,
          sort: true
        }]
        return data
      }
    }
  },
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit || this.$authFunsProxy.applyEdit || this.$authFunsProxy.applyAdd) {
      this.$store.dispatch('loadWorkerList')
    }
  },
  methods: {
    checkDataChange (data) {
      this.$checkedList = data
    },
    async print (datas) {
      if (!datas || !datas.length) {
        return void(this.$dialog.info({
          title: '提示',
          content: '请先选择要打印的数据'
        }))
      }

      const workerJobTitle = await workerJobTitleRequest.get()

      const title = datas.length > 1 ? '合同批量打印' : (datas[0].workerName + '的' + datas[0].type + '打印')
      let formData = await this.$form.open({
        oldData: {},
        submitBtnLabel: '确定',
        formTitle: title,
        labelAlone: true,
        labelAlign: 'left',
        formParms: [
          {
            type: 'input',
            label: '法定代表人',
            key: 'LEFT_USER',
            check: {
              required: true
            }
          },
          {
            type: 'textarea',
            label: '单位地址',
            key: 'LEFT_ADDRESS',
            check: {
              required: true
            }
          },
          {
            type: 'input',
            label: '单位所在地邮政编码',
            key: 'LEFT_CODE',
            check: {
              required: true
            }
          }
        ]
      })

      if (formData === false) {
        return
      }

      this.$refs.print.print('contract', title, (tpl) => {
        return datas.map(data => {
          let worker = this.$store.getters.workerList.find(v => v.key === data.id)
          worker = worker ? worker.data : {
            sex: '未知'
          }

          const workerJobTitles = workerJobTitle.filter(v2 => v2.workerId === data.id)
          const job = workerJobTitles.pop()

          const replace = {
            CODE: data.code,
            LEFT_NAME: data.signedOrg,
            LEFT_USER: formData.LEFT_USER,
            LEFT_ADDRESS: formData.LEFT_ADDRESS,
            LEFT_CODE: formData.LEFT_CODE,
            RIGHT_NAME: data.workerName,
            RIGHT_GENDER: worker.sex,
            RIGHT_ID: data.idNo,
            RIGHT_ADDRESS: '',
            RIGHT_CODE: '',
            START_YEAR: !data.startDate ? '-' : data.startDate.substr(0, 4),
            START_MONTH: !data.startDate ? '-' : data.startDate.substr(5, 2),
            START_DAY: !data.startDate ? '-' : data.startDate.substr(8, 2),
            END_YEAR: !data.endDate ? '-' : data.endDate.substr(0, 4),
            END_MONTH: !data.endDate ? '-' : data.endDate.substr(5, 2),
            END_DAY: !data.endDate ? '-' : data.endDate.substr(8, 2),
            TEST_START_YEAR: !data.needTry ? '-' : (!data.tryStartDate ? '-' : data.tryStartDate.substr(0, 4)),
            TEST_START_MONTH: !data.needTry ? '-' : (!data.tryStartDate ? '-' : data.tryStartDate.substr(5, 2)),
            TEST_START_DAY: !data.needTry ? '-' : (!data.tryStartDate ? '-' : data.tryStartDate.substr(8, 2)),
            TEST_END_YEAR: !data.needTry ? '-' : (!data.tryEndDate ? '-' : data.tryEndDate.substr(0, 4)),
            TEST_END_MONTH: !data.needTry ? '-' : (!data.tryEndDate ? '-' : data.tryEndDate.substr(5, 2)),
            TEST_END_DAY: !data.needTry ? '-' : (!data.tryEndDate ? '-' : data.tryEndDate.substr(8, 2)),
            JOB_POSITION: '未获取',
            JOB_TYPE: !job ? '无' : job.jobTitleType,
            SIGN_YEAR: !data.signedTime ? '-' : data.signedTime.substr(0, 4),
            SIGN_MONTH: !data.signedTime ? '-' : data.signedTime.substr(5, 2),
            SIGN_DAY: !data.signedTime ? '-' : data.signedTime.substr(8, 2)
          }
          Object.keys(replace).forEach(key => {
              tpl = tpl.replace('{{' + key + '}}', replace[key])
          })
          return tpl
        }).join('<div class="page-break" style="page-break-after:always"></div>')
      })
    },
    clickTitleMenu (key) {
      if (key === 'print') {
        this.print(this.$checkedList)
      }
    },
    tableAction ({data, action}) {
      if (action === 'print') {
        this.print([data])
      }
    },
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    dealFormData (data) {
      if (data.needTry !== '是') {
        data.tryStartDate = null
        data.tryEndDate = null
      }
      if (data.endDate && this.timeBj(data.startDate, data.endDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '到期时间需要大于起始时间。'
        })
        throw Error()
      }
      if (data.stopDate && this.timeBj(data.startDate, data.stopDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '终止时间需要大于起始时间。'
        })
        throw Error()
      }
      if (data.tryStartDate && data.tryEndDate && this.timeBj(data.tryStartDate, data.tryEndDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '试用期结束时间需要大于试用期开始时间。'
        })
        throw Error()
      }
      return data
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        fileUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}),
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    }
  },
  data () {
    return {
      request: request,
      choose: null,
      pageVm: null
    }
  }
}
</script>

<style lang="less" scoped>
.img-c {
  height: 60vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .no-img {
    font-size: 1.4rem;
    color: #999;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.file-d-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-d-footer-wx {
  display: none;
  justify-content: center;
  align-items: center;
}
</style>